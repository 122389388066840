<template>
    <div class="iframe_wrapper">
        <iframe  frameborder="0"  scrolling="auto" class="huixin_iframe" height="960" id="courseDetailIframe"  :src="iframeSrc" allowfullscreen="true"></iframe>
    </div>
</template>
<script>
import { getQureyObjectFromUrl } from '@/utils/index'
import { mapGetters } from 'vuex'

export default{
    name: 'coursedetail',
    data(){
        return{}
    },
    computed: {
        ...mapGetters([
        'name'
        ]),
        iframeSrc: function() {
            let classId = this.$route.params.id
            return `https://huixinic.gaoxiaobang.com/#/huixin/course/${classId}`
        }
    },
    mounted(){
        let vm = this
        let iframe = document.getElementById("courseDetailIframe");
        iframe.onload= function() {
            if(vm.name) {
                iframe.contentWindow.postMessage("hasLogin", 'https://huixinic.gaoxiaobang.com')
            } else{
                iframe.contentWindow.postMessage("noLogin", 'https://huixinic.gaoxiaobang.com')
            }
        }
        
        window.addEventListener("message", function(e) {
            if(e.origin == 'https://huixinic.gaoxiaobang.com' && e.data == 'noLogin'){
                const _tenantId = getQureyObjectFromUrl().tenantId|| 1422
                const service = `https://gateway.gaoxiaobang.com/user_info/redirect?redirect=${window.location.origin}${window.location.pathname}${window.location.hash}`
                window.location.href = `https://cas.gaoxiaobang.com/login?tenantId=${_tenantId}&service=${encodeURIComponent(service)}`
            }
            if (e.data && e.data.height) {
                document.getElementById("courseDetailIframe").style.height = e.data.height + 'px'
            }
        })

    },
    methods: {

    }
}
</script>
